(function() {
    'use strict';

    angular.module('ionicStateList', [
        'ionicAlert',
        'ionicData',
        'ionicFilter',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultVariable',
        'uabPromiseManagement'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicStateList').service('StateListService', StateListService);

    StateListService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function StateListService(
        DataManagementService,
        DefaultVariableService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var StateListService = this;

        StateListService.getStates = getStates;
        function getStates() {
            if (StateListService.states.length === 0) {
                return StateListService.loadStates();
            } else {
                return PromiseManagementService.generateSuccess(
                    StateListService.states
                );
            }
        }

        StateListService.loadStates = loadStates;
        function loadStates() {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'states');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setLimit(options, 100);

            options = RequestManagementService.setOrder(
                options,
                [
                    'name'
                ]
            );

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        StateListService.states = DefaultVariableService.getArray(
                            data.states
                        );

                        return StateListService.states;
                    }

                    return false;
                }
            );
        }

        StateListService.reset = reset;
        function reset() {
            StateListService.states = [];
        }

        StateListService.reset();

        return StateListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicStateList').controller(
        'StateFilterController',
        StateFilterController
    );

    StateFilterController.$inject = [
        'DefaultVariableService',
        'LoginService',
        '$scope',
        'StateListService'
    ];

    function StateFilterController(
        DefaultVariableService,
        LoginService,
        $scope,
        StateListService
    ) {
        var StateFilterController = this;

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                StateFilterController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }
        );

        $scope.$watch(
            'target',
            function(target) {
                StateFilterController.target = DefaultVariableService.getObject(
                    target
                );
            }
        );

        StateFilterController.clear = clear;
        function clear() {
            StateFilterController.reset();
            StateListService.reset();
        }

        StateFilterController.loadStates = loadStates;
        function loadStates(options) {
            StateFilterController.isLoadingStates = true;

            return StateListService.getStates(options).then(
                function(states) {
                    if (states) {
                        StateFilterController.states = DefaultVariableService.getArray(
                            states
                        );
                    }

                    return states;
                }
            ).finally(
                function() {
                    StateFilterController.isLoadingStates = false;
                }
            );
        }

        StateFilterController.select = select;
        function select(state) {
            StateFilterController.onSelect(state);
        }

        StateFilterController.reset = reset;
        function reset() {
            StateFilterController.isLoadingStates = false;

            StateFilterController.states = [];
        }

        StateFilterController.init = init;
        function init() {
            StateFilterController.reset();
            StateFilterController.loadStates();
        }

        LoginService.register(StateFilterController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicStateList').directive('ionicStateFilter', ionicStateFilter);

    function ionicStateFilter() {
        return {
            controller:   'StateFilterController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                onSelect: '=',
                target:   '='
            },
            template:'<div><ionic-filter on-filter="ctrl.loadStates" on-select="ctrl.select" target="ctrl.target" title="name" toggle-message="SELECT A STATE..."></ionic-filter></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicStateList').controller(
        'StateListController',
        StateListController
    );

    StateListController.$inject = [
        'LoginService',
        'StateListService'
    ];

    function StateListController(
        LoginService,
        StateListService
    ) {
        var StateListController = this;

        StateListController.clear = clear;
        function clear() {
            StateListController.reset();
            StateListService.reset();
        }

        StateListController.loadStates = loadStates;
        function loadStates() {
            StateListController.isLoadingStates = true;

            return StateListService.getStates().then(
                function(states) {
                    if (states) {
                        StateListController.states = DefaultVariableService.getArray(
                            states
                        );
                    }

                    return states;
                }
            ).finally(
                function() {
                    StateListController.isLoadingStates = false;
                }
            );
        }

        StateListController.reset = reset;
        function reset() {
            StateListController.isLoadingStates = false;

            StateListController.states = [];
        }

        StateListController.init = init;
        function init() {
            StateListController.reset();
            StateListController.loadStates();
        }

        LoginService.register(StateListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicStateList').directive('ionicStateList', ionicStateList);

    function ionicStateList() {
        return {
            controller:   'StateListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div><ul><li data-ng-repeat="state in ctrl.states">{{ state.name }} ({{ state.abbreviation }})</li><li data-ng-if="ctrl.isLoadingStates">Loading...</li></ul></div>'
        };
    }
})();